// 0-表示未设置，1-爸爸，2-妈妈，3-爷爷，4-奶奶，5-外公，6-外婆

export default [
    {
        id: 0,
        name: "未设置"
    },
    {
        id: 1,
        name: "爸爸"
    },
    {
        id: 2,
        name: "妈妈"
    },
    {
        id: 3,
        name: "爷爷"
    },
    {
        id: 4,
        name: "奶奶"
    },
    {
        id: 5,
        name: "外公"
    },{
        id: 6,
        name: "外婆"
    }
]
