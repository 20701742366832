<template>
    <div class="family">
        <div class="family-user">
            <van-row v-for="user in users" :key="user.name" type="flex" justify="center">
                <van-col span="6">
                    <van-image
                            round
                            :src="user.imgUrl"
                    />
                    <van-image
                            v-if="user.isAdmin"
                            class="family-user-crown"
                            :src="require('@/assets/crown.png')"
                    />
                </van-col>
                <van-col span="12" offset="2">
                    <div class="family-user-info">
                        <div class="family-user-info-name">昵称：{{user.name}}</div>
                        <div>
                            身份：
                            <select class="home-user-info-role" v-if="isAdmin" @change="changeRole($event, user.openid)">
                                <option :value ="role.id" v-for="role in roles" :key="role.id" :selected="role.id==user.role">{{role.name}}</option>
                            </select>
                            <span v-else>{{roles[user.role].name}}</span>
                            <span v-if="isAdmin">
                                <van-button v-if="user.isAdmin==true" type="primary" size="mini" text="添加成员" @click="show = true"></van-button>
                                <van-button v-else type="primary" size="mini" text="移除" @click="deleteUser(user.name, user.openid)"></van-button>
                            </span>
                        </div>
                    </div>
                </van-col>
            </van-row>
        </div>
        <van-overlay :show="show" @click="show = false">
            <div class="family-wrapper">
                <div>
                    <van-image :src="require('@/assets/wx_code.jpg')" />
                    <p>扫一扫，识别图中二维码</p>
                    <p>轻松加入家庭</p>
                </div>
            </div>
        </van-overlay>
    </div>
</template>
<script>
import {Col, Row, Icon, Button, Dialog, Image as VanImage, Overlay, Toast } from 'vant';
import { getMembers, unBindDevice, changeRole } from '@/api/wx';
import roles from '@/utils/userRole'

export default {
    name: "family",
    components: {
        [Col.name]: Col,
        [Row.name]: Row,
        [Icon.name]: Icon,
        [Button.name]: Button,
        [VanImage.name]: VanImage,
        [Dialog.Component.name]: Dialog.Component,
        [Overlay.name]: Overlay,
        [Toast.name]: Toast,
    },
    data() {
        return {
            deviceId: '',
            isAdmin: false,
            users: [],
            roles: roles,
            roleSelected: 0,
            show: false
        }
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this.deviceId = this.$store.getters.deviceId;
            this.getMembers(this.deviceId);
        },
        getMembers(deviceId) {
            this.isAdmin = this.$store.getters.isAdmin;
            getMembers({deviceId: deviceId}).then(response => {
                if(response.data.code == 0){
                    this.users = response.data.data.list;
                }else {
                    Dialog.alert({
                        message: '获取家庭成员信息失败，错误码:'+ response.data.code,
                    }).then(() => {
                        // on close
                    });
                }
            })
        },
        changeRole(event, openid) {
            changeRole({openid: openid, deviceId: this.deviceId, role: event.target.value}).then(response => {
                if (response.data.code == 0){
                    Toast("设置身份成功")
                } else {
                    Dialog.alert({
                        message: '设置用户身份失败，错误码:'+ response.data.code,
                    }).then(() => {
                        // on close
                    });
                }
            })
        },
        deleteUser(name, openid) {
            Dialog.confirm({
                title: '移除成员',
                message: '确定移除'+ name +'该家庭成员吗',
            }).then(() => {
                // on confirm
                unBindDevice({openid: openid, deviceId: this.deviceId}).then(response => {
                    if (response.data.code == 0) {
                        Toast("解绑成功")
                        this.getMembers(this.deviceId);
                    }else {
                        Dialog.alert({
                            message: '解绑失败，错误码:'+ response.data.code,
                        }).then(() => {
                            // on close
                        });
                    }
                })
            }).catch(() => {
                // on cancel
            });
        }
    }
}
</script>
<style lang="less" scoped>
.family {
    &-user {
        margin-top: 16px;
        .van-row {
            padding: 8px 0;
        }
        .van-col{
            position: relative;
        }
        .van-image{
            width: 80px;
            height: 80px;
        }
        &-crown {
            position: absolute;
            transform: translate(-75%, -50%) rotate(30deg);
        }
        &-info {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            font-size: 16px;
            &-name {
                margin-bottom: 8px;
            }
            &-role {
                width: 66px;
                height: 20px;
                font-size: 12px;
            }
            .van-button {
                position: absolute;
                right: 0;
            }
        }
    }
    &-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        .van-image{
            width: 200px;
            height: 200px;
        }
    }
    &-wrapper p{
        color: #fff;
        text-align: center;
        font-size: 16px;
    }
}
</style>
